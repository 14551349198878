// useHttpRequests.js
import apiClient from "@/api/api";
import qs from "qs";

export function useHttpRequests() {

    async function getLoggedInUser() {
        try {
            const res = await apiClient.get('/users/me?populate=*');
            console.log("GET me SUCCESS", res.data);

            return res.data;
        } catch (error: any) {
            console.error("GET me FAILED", error);
            return {};
        }
    }

    async function getSimulations( areaId: number, userId?: number) {
        const query = qs.stringify({
          //populate: '*',
          filters: {
            $or:[
                {
                    user_fk: { 
                        id: {
                            $eq: userId,
                        },
                    },
                },
                {
                    type: {
                        $ne: 'private'
                    },
                },
            ],
            area_fk: { 
                id: {
                    $eq: areaId,
                },
            },
          },
        });
        try {
            const simulationRes = await apiClient.get("/simulations?" + query);
            console.log("GET simulations SUCCESS areaId "+areaId + " / userId "+userId);

            return simulationRes.data.data;
        } catch ( error: any ) {
            console.error("GET simulations FAILED", error);
            return [];
        }
    }

    async function getInitialGeodata( areaId : number ) {        
        const query = qs.stringify({
            filters: {
                area_fk: {
                    id: {
                        $eq: areaId,
                    },
                },
            },
        });
        try {
            const initialGeodataResp = await apiClient.get("/initial-geodata?" + query);
            console.log("GET initial-geodata SUCCESS", areaId, initialGeodataResp.data.data);
            return initialGeodataResp.data.data;
        } catch (error) {
            console.error("GET initialGeodata FAILED", error);
            return [];
        }
    }

    async function getSimResultsForTicks( simId: number, tickNumbers: number[], simRunId: number = 1) {
        console.log("getSimResultsForTick ", tickNumbers, " run id: "+simRunId);
        if(!simRunId){
            simRunId = 1;
        }
        const query = qs.stringify({
          filters: {
            $and: [
                {
                    sim_fk: {
                        id: {
                            $eq: simId,
                        },
                    }
                },
                {
                    sim_run_id: {
                        $eq: simRunId,
                    },
                },
                {
                    tick: {
                        $in: tickNumbers,
                    }
                }
            ]
          },
        });
        try {
            const simResultsRespForTicks = await apiClient.get("/sim-results?" + query);
            console.log("GET getSimResultsForTick SUCCESS "+ tickNumbers.toString());//simResultsRespForTicks.data.data);
            return simResultsRespForTicks.data.data;
        } catch (error) {
            console.error("GET getSimResultsForTick FAILED "+ tickNumbers.toString(), error);
            return [];
        }
    }

    async function getSimRunAnalysesForTicks( simId: number, tickNumbers: number[], simRunId: number = 1) {
        console.log("getSimRunAnalysesForTicks ", tickNumbers);
        if(!simRunId){
            simRunId = 1;
        }
        const query = qs.stringify({
          filters: {
            $and: [
                {
                    sim_fk: {
                        id: {
                            $eq: simId,
                        },
                    }
                },
                {
                    sim_run_id: {
                        $eq: simRunId,
                    },
                },
                {
                    tick: {
                        $in: tickNumbers,
                    }
                }
            ]
          },
        });
        try {
            const simRunAnalysesRespForTicks = await apiClient.get("/sim-run-analyses?" + query);
            console.log("GET getSimRunAnalysesForTicks SUCCESS "+tickNumbers.toString()); //simRunAnalysesRespForTicks.data.data);
            return simRunAnalysesRespForTicks.data.data;
        } catch (error) {
            console.error("GET getSimRunAnalysesForTicks FAILED "+tickNumbers.toString(), error);
            return [];
        }
    }
    async function getSimTemplates() {
        try {
            const templateRes = await apiClient.get("/sim-templates?populate=*");
            console.log("GET templates SUCCESS", templateRes.data.data);

            return templateRes.data.data;
        } catch ( error: any ) {
            console.error("GET templates FAILED", error);
            return [];
        }
    }
          
    async function insertSimulation(areaId: any, userId: any, simName: any, abmParams: any, simDescription: string){
        const payload = {
            area_fk: {
              connect: [
              {
                id: Number(areaId)
              }
            ]
            },   
            type: 'public',
            name: simName,
            status: 'created',
            parameters_abm: abmParams
          }

        if(userId){
            Object.assign(payload, {
                user_fk: {
                    connect: [
                        {
                        id: Number(userId)
                        }
                    ]
                }
            });
            payload.type = 'private'
        }
        if(simDescription){
            Object.assign(payload, {
                description: simDescription
            });
        }

        try {
            const response = await apiClient.post("/simulations", payload);
            console.log("POST simulation SUCCESS", response.data.data);

            return response.data.data.id;
        } catch ( error: any ) {
            console.error("POST simulation FAILED", error);
            return undefined;
        }          
    }

    async function updateSimulation(abmParams: any, simId: any){
        const payload = {
            parameters_abm: abmParams
        }

        try {
            const response = await apiClient.put("/simulations/"+simId, payload);
            console.log("PUT simulation SUCCESS", response.data.data);

            return response.data.data.id;
        } catch ( error: any ) {
            console.error("PUT simulation FAILED", error);
            return undefined;
        }           

    }

    async function startSimulation(simId: any) {
        console.log("startSimulation "+ simId);

        try {
            const response = await apiClient.post(`/startSimulation/${simId}/repast-30-09-2024`);
            console.log("START simulation SUCCESS", response.data.data);
            return true;

        } catch ( error: any ) {

            console.error("START simulation FAILED", error);
            return false;
        } 
    }

    async function updateSimulationType(newType: string, simId: any){
        const payload = {
            type: newType
        }

        try {
            const response = await apiClient.put("/simulations/"+simId, payload);
            console.log("PUT simulation state SUCCESS", response.data.data);

            return response.data.data.id;
        } catch ( error: any ) {
            console.error("PUT simulation state FAILED", error);
            return undefined;
        }           

    }  
    
    async function deleteSimulation(simId: any){

        try {
            const response = await apiClient.delete("/simulations/"+simId);
            console.log("DELETE simulation SUCCESS", response.data.data);

            return response.data.data.id;
        } catch ( error: any ) {
            console.error("DELETE simulation FAILED", error);
            return undefined;
        }           

    }     

  return {getLoggedInUser, getSimResultsForTicks, getInitialGeodata, getSimulations, getSimRunAnalysesForTicks, getSimTemplates, insertSimulation, updateSimulation, startSimulation, updateSimulationType, deleteSimulation}
}